<!-- 开发者平台详情页模板 -->
<template>
  <div class="mobile-container mobile-developer-detail" v-title="title">
    <div class="header">
      <mobile-header></mobile-header>
    </div>
    <div class="body">
      <div class="graph">
        <div class="graph-wrapper" :style="`height: ${detail.imageHeight}rem;`">
          <div class="graph-scroll">
            <img :src="require(`@/assets/image/mobile/photo-${detail.image}.png`)" :style="`width: ${detail.imageWidth}rem; height: ${detail.imageHeight}rem;`">
          </div>
        </div>
      </div>
      <div class="list">
        <div class="title">{{ detail.title }}</div>
        <div class="item discript" v-for="(item, index) in detail.discript" :key="index">
        {{ item }}</div>
      </div>
    </div>
    <!-- 返回主页 -->
    <div class="mobile-back" @click="back">
      <img src="@/assets/image/mobile/icon-33.png">
    </div>
  </div>
</template>

<script>
  // 头部
  import MobileHeader from '@/components/mobile/mobile-header/mobile-header'
  import DeveloperData from './developer-data'

  export default {
    name: 'MobileDeveloperDetail',
    data () {
      return {
        title: '',
        detail: {
          image: '1',
          title: '',
          discript: []
        }
      }
    },
    components: {
      MobileHeader
    },
    methods: {
      // 返回
      back () {
        this.$router.push({
          path: '/mobileDeveloper'
        })
      },
      // 初始化
      init () {
        let id = this.$route.params.id
        let arr = DeveloperData.filter(item => item.id === id)
        if (arr.length > 0) {
          this.title = arr[0].simple + arr[0].title
          this.detail = arr[0].detail
        }
      }
    },
    mounted () {
      this.init()
    }
  }
</script>

<style lang="scss">
  @import '@/components/mobile/style/common.scss';

  .mobile-developer-detail {
    .body {
      .graph {
        padding: $distance-1;
        .graph-wrapper {
          overflow: hidden;
          .graph-scroll {
            overflow-x: auto;
            padding-bottom: 0.15rem;
          }
        }
      }
      .list {
        background-color: $white-color;
        padding: 0.5rem;
        font-size: $font-size-3;
        font-family: Source Han Sans CN;
        font-weight: 500;
        line-height: 0.46rem;
        color: $text-color-1;
        .title {
          margin-bottom: 0.5rem;
        }
        .item {
          margin-bottom: 0.5rem;
        }
        & > div {
          position: relative;
          padding-left: 0.26rem;
          &:before {
            content: '';
            width: 0.08rem;
            height: 0.08rem;
            border-radius: 0.04rem;
            background-color: $text-color-3;
            position: absolute;
            left: 0;
            top: 0.19rem;
          }
        }
      }
    }
  }
</style>